import { CacheType, getCatalogId, IActionContext, IActionInput, ICommerceApiSettings, IRequestContext } from '@msdyn365-commerce/core';
import { Cart, CategoryPathLookup } from '@msdyn365-commerce/retail-proxy';
import { GaEvent, GaItem, GaPayload } from './pushGoogleAnalyticsEvent';
import { getProductDetails } from './utilities/get-product-details';

export const buildCacheKey = (base: string, apiSettings: ICommerceApiSettings, locale?: string): string => {
    return `${base}-chanId:${apiSettings.channelId}-catId:${apiSettings.catalogId}${locale ? `-${locale}` : ''}`;
};

/**
 * Category path input.
 */
export class GetCategoryPathsInput implements IActionInput {
    public readonly ChannelId: number;

    public readonly CatalogId: number;

    public readonly categoryPathLooksups: CategoryPathLookup[];

    public readonly apiSettings: ICommerceApiSettings;

    private readonly locale: string;

    private constructedCacheKey: string;

    public constructor(context: IRequestContext, categoryPathLooksups: CategoryPathLookup[]) {
        this.ChannelId = context.apiSettings.channelId;
        this.CatalogId = getCatalogId(context);
        this.categoryPathLooksups = categoryPathLooksups;
        this.apiSettings = context.apiSettings;
        this.constructedCacheKey = '';
        categoryPathLooksups.forEach(categoryPath => {
            this.constructedCacheKey += `${categoryPath.ProductId && categoryPath.ProductId.toString()}|`;
        });
        this.constructedCacheKey += `${this.ChannelId.toString()}|`;
        this.constructedCacheKey += `${this.CatalogId.toString()}|`;
        this.locale = context.locale;
    }

    public getCacheKey = () => buildCacheKey(this.constructedCacheKey, this.apiSettings, this.locale);

    public getCacheObjectType = () => 'CategoryPath';

    public dataCacheType = (): CacheType => 'request';

    /**
     * Retrieves locale to use for the current API call.
     * @returns A string which represents locale.
     */
    public getLocale = (): string => this.locale;
}

const createGoogleAnalyticsEvent = async (cart: Readonly<Cart>, eventName: GaEvent, actionContext: IActionContext): Promise<GaPayload> => {
    var channelId = actionContext.requestContext.channel?.RecordId;

    const items: GaItem[] = [];

    if (channelId && cart.CartLines) {
        let i = 0;
        for (const cartLine of cart.CartLines) {
            if (cartLine.ProductId) {
                const productDetails = await getProductDetails(cartLine.ProductId, channelId, actionContext);

                const product = productDetails.product;
                const variant = product?.Dimensions?.map(d => d.DimensionValue?.Value).join(',');

                process.env.NODE_ENV === 'development' &&
                    console.log('createGoogleAnalyticsEvent', cartLine.ProductId, JSON.parse(JSON.stringify(product)));

                const discount = (cartLine.DiscountLines ?? []).reduce((a, b) => a + (b.Amount ?? 0), 0);

                var brand = product?.ExtensionProperties?.find(ep => ep.Key === 'Brand')?.Value?.StringValue;

                var item = {
                    recId: cartLine.ProductId,
                    item_id: product?.ProductNumber || product?.ItemId || cartLine.ItemId || '',
                    item_name: product?.Name ?? '',
                    index: i,
                    item_variant: variant,
                    price: (product?.Price ?? cartLine.Price ?? 0) - discount,
                    discount: discount,
                    pid: product?.ItemId || cartLine.ItemId || '',
                    item_brand: brand,
                    quantity: cartLine.Quantity ?? 0
                };

                items.push(item);
            }

            i++;
        }
    }

    const total = items.reduce((a, b) => a + (b.price ?? 0) * b.quantity, 0);

    return {
        event: eventName,
        ecommerce: {
            currency: 'CAD',
            value: total,
            items: items
        }
    };
};

export { createGoogleAnalyticsEvent };
