import { IActionContext } from '@msdyn365-commerce/core';
import { GaItem, pushGoogleAnalyticsEvent } from './pushGoogleAnalyticsEvent';
import { OrgUnitLocation, ProductsDataActions, SimpleProduct } from '@msdyn365-commerce/retail-proxy';

interface IAddCartLine {
    product: SimpleProduct;
    count?: number;
    location?: OrgUnitLocation;
    additionalProperties?: object;
    availableQuantity?: number;
    enableStockCheck?: boolean;
    isAddEmailDeliveryItemToCart?: boolean;
    isPriceKeyedIn?: boolean;
    customPrice?: number;
    deliveryMode?: string;
    catalogId?: number;
    shouldSkipSiteSettings?: boolean;
}

export const sendGoogleAnalyticsEvent = async (products: IAddCartLine[], actionContext: IActionContext) => {
    try {
        const apiSettings = actionContext.requestContext.apiSettings;
        const gaItems: GaItem[] = [];

        const prices = await ProductsDataActions.getActivePricesAsync(
            { callerContext: actionContext },
            {
                ChannelId: apiSettings.channelId,
                CatalogId: apiSettings.catalogId
            },
            products.map(p => p.product.RecordId),
            new Date(),
            null,
            null,
            true,
            null,
            true
        );

        for (const p of products) {
            const product = p.product;
            const variant = product.Dimensions?.map(d => d.DimensionValue?.Value).join(',');
            const brandFromExt = product?.ExtensionProperties?.find(p => p.Key === 'Brand')?.Value?.StringValue;

            const productPrice = prices.find(price => price.ProductId === p.product.RecordId);

            const discount = (productPrice?.DiscountLines ?? []).reduce((a, b) => a + ((b.EffectiveAmount || b.Amount) ?? 0), 0);

            gaItems.push({
                recId: product.RecordId,
                item_id: product.ProductNumber || product.ItemId || '',
                item_name: product.Name ?? '',
                index: gaItems.length,
                item_variant: variant,
                price: product.Price - discount,
                discount: discount,
                pid: product.ItemId || '',
                item_brand: brandFromExt ?? undefined,
                quantity: p.count ?? 0
            });
        }

        const totalValue = gaItems.reduce((a, b) => a + (b.price ?? 0) * b.quantity, 0);

        await pushGoogleAnalyticsEvent(
            {
                event: 'add_to_cart',
                ecommerce: {
                    currency: 'CAD',
                    value: totalValue,
                    items: gaItems
                }
            },
            actionContext
        );
    } catch (err) {
        console.error(err);
    }
};
