import { IActionContext } from '@msdyn365-commerce/core';
import { GetCategoryPathsInput } from './createGoogleAnalyticsEvent';
import { getCategoryPaths } from '@msdyn365-commerce-modules/retail-actions';
import { encodeKlaviyoItemId } from './encodeKlaviyoItemId';

import { getCartState } from '@msdyn365-commerce/global-state';
import { ProductsDataActions, SimpleProduct } from '@msdyn365-commerce/retail-proxy';

export type GaDataLayer = {
    push: (payload: GaPayload) => void;
};

export type GaItem = {
    item_id: string;
    item_name: string;
    affiliation?: string;
    coupon?: string;
    discount?: number;
    index: number;
    item_brand?: string;
    item_category?: string;
    item_category2?: string;
    item_category3?: string;
    item_category4?: string;
    item_category5?: string;
    item_list_id?: string;
    item_list_name?: string;
    item_variant?: string;
    location_id?: string;
    price: number;
    quantity: number;
    pid?: string;
    recId?: number;
};

export type GaEvent =
    | 'view_item'
    | 'view_cart'
    | 'purchase'
    | 'begin_checkout'
    | 'add_to_cart'
    | 'add_to_wishlist'
    | 'remove_from_cart'
    | 'add_shipping_info'
    | 'add_payment_info';

export type GaPayload = {
    event?: GaEvent;
    ecommerce?: {
        transaction_id?: string;
        cart_id?: string;
        currency?: string;
        value: number;
        tax?: number;
        shipping?: number;
        shipping_tier?: string;
        payment_type?: string;
        coupon?: string;
        items?: GaItem[];
    } | null;
};

export const pushGoogleAnalyticsEvent = async (payload?: GaPayload, actionContext?: IActionContext) => {
    const dataLayer = typeof window !== 'undefined' && (window['dataLayer'] as GaDataLayer);
    if (dataLayer) {
        process.env.NODE_ENV === 'development' && console.log('pushGoogleAnalyticsEvent', payload);
        dataLayer.push({ ecommerce: null });
        if (payload) {
            for (const gaItem of payload?.ecommerce?.items ?? []) {
                if (gaItem.recId) {
                    if (actionContext) {
                        const categoryPaths = await getCategoryPaths(
                            new GetCategoryPathsInput(actionContext.requestContext, [{ ProductId: +gaItem.recId }]),
                            actionContext
                        );

                        if (categoryPaths.length > 0) {
                            gaItem.item_category = categoryPaths[0].Name ?? undefined;
                        }
                        if (categoryPaths.length > 1) {
                            gaItem.item_category2 = categoryPaths[1].Name ?? undefined;
                        }
                        if (categoryPaths.length > 2) {
                            gaItem.item_category3 = categoryPaths[2].Name ?? undefined;
                        }
                        if (categoryPaths.length > 3) {
                            gaItem.item_category4 = categoryPaths[3].Name ?? undefined;
                        }
                        if (categoryPaths.length > 4) {
                            gaItem.item_category5 = categoryPaths[4].Name ?? undefined;
                        }
                    }

                    delete gaItem.recId;
                }
            }

            dataLayer.push(payload);
        }
    }

    const klaviyo = window?.klaviyo;
    if (payload && klaviyo) {
        if (payload.event === 'view_item') {
            const product = payload.ecommerce?.items && payload.ecommerce.items[0];
            if (product) {
                klaviyo.push([
                    'track',
                    'Viewed Product',
                    {
                        ProductName: product.item_name,
                        ProductID: encodeKlaviyoItemId(product.item_id, ''),
                        SKU: product.item_id,
                        Price: product.price
                    }
                ]);
            }
        } else if (payload.event === 'begin_checkout' && payload.ecommerce?.items?.length) {
            klaviyo.push([
                'track',
                'Started Checkout',
                {
                    $event_id: `${payload.ecommerce?.cart_id}_${new Date().getTime()}`,
                    $value: payload.ecommerce?.value,
                    ItemNames: payload.ecommerce.items.map(p => p.item_name),
                    Items: payload.ecommerce.items.map(product => ({
                        ProductID: encodeKlaviyoItemId(product.item_id, ''),
                        SKU: product.item_id,
                        ProductName: product.item_name,
                        Quantity: product.quantity,
                        ItemPrice: product.price,
                        RowTotal: product.quantity * product.price
                    }))
                }
            ]);
        } else if (payload.event === 'add_to_cart' && payload.ecommerce?.items?.length && actionContext) {
            const cartState = await getCartState(actionContext);
            const allProductIds = cartState.cart.CartLines?.filter(cl => !!cl.ProductId!).map(cl => cl.ProductId!) ?? [];
            if (allProductIds.length > 0) {
                const allProducts = await ProductsDataActions.getByIdsAsync(
                    { callerContext: actionContext },
                    actionContext.requestContext.channel?.RecordId ?? 0,
                    allProductIds
                );

                const allProductLookup: { [id: string]: SimpleProduct } = {};
                allProducts.forEach(p => (allProductLookup[p.RecordId] = p));

                var allItemsInCart =
                    cartState.cart.CartLines?.map(cl => {
                        const product = allProductLookup[cl.ProductId ?? 0];
                        if (product) {
                            return {
                                ProductID: encodeKlaviyoItemId(product.ProductNumber ?? product.ItemId ?? '', ''),
                                SKU: product.ProductNumber ?? product.ItemId ?? '',
                                ProductName: product.Name ?? '',
                                Quantity: cl.Quantity ?? 1,
                                ItemPrice: cl.Price ?? 0,
                                RowTotal: (cl.Quantity ?? 1) * (cl.Price ?? 0)
                            };
                        }

                        return null;
                    })
                        .filter(p => !!p)
                        .map(p => p!) ?? [];

                if (allItemsInCart.length > 0) {
                    const itemNames = allItemsInCart.map(p => p.ProductName);
                    let cartTotal = 0;
                    allItemsInCart.forEach(p => {
                        cartTotal += p.RowTotal;
                    });

                    payload.ecommerce?.items.forEach(addedItem => {
                        klaviyo.push([
                            'track',
                            'Added to Cart',
                            {
                                $value: cartTotal,

                                AddedItemProductName: addedItem.item_name,
                                AddedItemProductID: encodeKlaviyoItemId(addedItem.item_id, ''),
                                AddedItemSKU: addedItem.item_id,
                                AddedItemPrice: addedItem.price,
                                AddedItemQuantity: addedItem.quantity,
                                ItemNames: itemNames,
                                Items: allItemsInCart
                            }
                        ]);
                    });
                }
            }
        }
    }
};
