import * as React from 'react';
import { widgetType as WidgetType } from '../bazzarvoice-widget.props.autogenerated';
import MsDyn365 from '@msdyn365-commerce/core';
import classnames from 'classnames';

type BazzarvoiceProps = {
    itemId?: string;
    widgetType?: WidgetType;
    className?: string;
    disableSeo?: boolean;
};

const Bazzarvoice = (props: BazzarvoiceProps) => {
    const { itemId, widgetType, className, disableSeo } = props;
    const divRef = React.useRef<HTMLDivElement>(null);
    const [initialized, setInitialized] = React.useState(false);

    const isBrowser = MsDyn365.isBrowser;
    React.useEffect(() => {
        const div = divRef.current;
        if (!initialized && isBrowser && div && itemId && widgetType) {
            setInitialized(true);

            div.innerHTML = `<div data-bv-show="${widgetType}" data-bv-product-id="${itemId}" data-bv-seo="${
                disableSeo ? 'false' : 'true'
            }" />`;
        }
    }, [isBrowser, initialized, itemId, widgetType, disableSeo, divRef]);

    return (
        <div className={classnames('hei-bazzarvoice-widget', className)}>
            <div ref={divRef} />
        </div>
    );
};

export default Bazzarvoice;
