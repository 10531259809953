import { AttributeValue, CommerceProperty, ProductSearchResult } from '@msdyn365-commerce/retail-proxy';
import * as Msdyn365 from '@msdyn365-commerce/core';

export enum ProductStockStatusEnum {
    OUT_OF_STOCK,
    IN_STORE,
    ONLINE
}

export interface IProductBadgeStatus {
    url: string;
    badgeName: string;
}

/**
 * Retrieves attribute from attribute list given the name of the attribute
 * @param attributes attribute list
 * @param attributeName name of the attribute to find
 */
export const findAttribute = (attributes: AttributeValue[] | undefined, attributeName: string): AttributeValue | undefined => {
    if (!attributes) {
        return undefined;
    }
    return attributes.find(attr => attr.Name?.toLowerCase() === attributeName.toLowerCase());
};

export const findAllAttributes = (attributes: AttributeValue[] | undefined, attributeName: string): AttributeValue[] => {
    if (!attributes) {
        return [];
    }
    return attributes.filter(attr => attr.Name?.toLowerCase() === attributeName.toLowerCase());
};

/**
 * Gets value of given attribute based on its DataTypeValue enum.
 * @param attribute attribute to retrieve value from
 */
export const findAttributeValue = (attribute: AttributeValue): string | Date | number | boolean | undefined => {
    switch (attribute.DataTypeValue) {
        case 1:
            return attribute.CurrencyCode;
        case 2:
            return attribute.DateTimeOffsetValue;
        case 3:
            return attribute.FloatValue;
        case 4:
            return attribute.IntegerValue;
        case 5:
            return attribute.TextValue;
        case 6:
            return attribute.BooleanValue;
        default:
            return undefined;
    }
};

/**
 * Builds URL to the product badge
 * @param locale current locale
 * @param badgeName name of the badge
 * @param baseUrl base url for images
 */
export const buildProductBadgeUrl = (locale: string, badgeName: string, baseUrl: string, suffix?: string): string => {
    return `${baseUrl}Catalogs/${locale}/${badgeName.toLowerCase().replace(/ /g, '_')}_${locale}${suffix || ''}.png`;
};

/**
 * Gets the correct product badge status from the product
 * @param product Product to get the badge for
 * @param locale current locale
 * @param baseUrl image base url
 * @returns product badge url
 */
export const getProductBadgeUrl = (
    product: ProductSearchResult,
    locale: string,
    baseUrl: string,
    suffix?: string
): IProductBadgeStatus | null => {
    const badgeAttributeArray = findAllAttributes(product.AttributeValues, 'Product Badge Status');
    let badgeStatus: IProductBadgeStatus | null = null;
    if (badgeAttributeArray) {
        badgeAttributeArray.forEach(attr => {
            const badgeValue = findAttributeValue(attr);
            if (badgeValue && typeof badgeValue === 'string' && badgeValue.toLowerCase() !== 'none') {
                badgeStatus = { url: buildProductBadgeUrl(locale, badgeValue, baseUrl, suffix), badgeName: badgeValue.replace(/_/, ' ') };
            }
        });
    }
    if (badgeStatus) {
        return badgeStatus;
    } else if (product.BasePrice && product.Price !== product.BasePrice) {
        return { url: buildProductBadgeUrl(locale, 'on_sale', baseUrl, suffix), badgeName: 'On Sale' };
    }
    return null;
};

/**
 * gets product stock status value from extension properties
 * @param extensionProperties extension properties from product
 */
export const getProductStockStatus = (extensionProperties: CommerceProperty[]): ProductStockStatusEnum => {
    if (extensionProperties?.length) {
        const availableProperty = extensionProperties.find(xt => xt.Key === 'AvailabilityStatus');
        if (availableProperty && availableProperty.Value && availableProperty.Value.IntegerValue) {
            return availableProperty.Value.IntegerValue;
        }
    }
    return ProductStockStatusEnum.OUT_OF_STOCK;
};

export const renderProductBadge = (request: Msdyn365.IRequestContext, badgeStatus: string, suffix: string) => {
    try {
        process.env.NODE_ENV === 'development' && console.log('renderProductBadge', badgeStatus, suffix);
        const mainImage = document?.querySelectorAll('.ms-buybox__media-gallery .msc-carousel__inner')[0];
        if (mainImage) {
            const currentBadge = mainImage.querySelector('.hei-badge');
            if (!currentBadge) {
                const badgeUrl = buildProductBadgeUrl(request.locale, badgeStatus, request.apiSettings.baseImageUrl, suffix);

                const badgeImage = document.createElement('img');
                badgeImage.src = badgeUrl;
                badgeImage.classList.add('msc-product__badge');
                badgeImage.classList.add('msc-product__badge--detail');
                badgeImage.classList.add('hei-badge');
                badgeImage.alt = badgeStatus;
                mainImage.appendChild(badgeImage);
            }

            return true;
        }
    } catch {
        return false;
    }

    return false;
};
